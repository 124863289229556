import { IButtonStyles, INavLink, IStyle, KeyCodes, Stack } from '@fluentui/react';
import { IContextualMenuItem, IContextualMenuListProps } from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react/lib/SearchBox';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { Button, IH2OTheme, IStyleFunctionOrObject, Link, Nav, useClassNames, useTheme } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Workspace } from '../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import { stackStylesPage } from '../../themes/themes';
import { useLeftPanel } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { ENDPOINTS } from './apiEndpoints';
import { useWorkspaces } from './WorkspaceProvider';

interface INavigationStyles {
  workspaceWidget: IStyle;
  widgetTitle: IStyle;
  contextualMenu: IStyle;
}

interface INavigationWrapperStyles {
  container: IStyle;
  stack: IStyle;
}

const navigationStyles = (theme: IH2OTheme): Partial<INavigationStyles> => ({
    workspaceWidget: {
      backgroundColor: theme.semanticColors?.bodyBackground,
      padding: '20px 10px',
      borderRadius: 8,
      position: 'absolute',
      bottom: 40,
      maxWidth: 246,
    },
    widgetTitle: { color: theme.semanticColors?.messageBarTitleText, marginTop: 0 },
    contextualMenu: { width: 226 },
  }),
  navigationWrapperStyles = (): Partial<INavigationWrapperStyles> => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    stack: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
  }),
  filteredItemsStyle: React.CSSProperties = {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBoxStyles: ISearchBoxStyles = {
    root: { margin: '8px' },
  },
  accessControlStyles: IStyleFunctionOrObject<IButtonStyles> | IStyleFunctionOrObject<IButtonStyles>[] | undefined = {
    label: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 18,
    },
    root: {
      height: 20,
      minHeight: 20,
      padding: '0 8px',
      border: 'none',
    },
  };

const Navigation = ({
  workspaces,
  activateWorkspace,
  activeWorkspace,
}: {
  activeWorkspace?: Workspace;
  workspaces?: Workspace[];
  activateWorkspace: (name: string) => void;
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string>(),
    history = useHistory(),
    theme = useTheme(),
    classNames = useClassNames<INavigationStyles, ClassNamesFromIStyles<INavigationStyles>>(
      'navigationPane',
      navigationStyles(theme)
    ),
    menuItems: IContextualMenuItem[] = React.useMemo(
      () =>
        workspaces?.map((w) => ({
          key: w.name || '',
          text: w.displayName,
          iconProps: w.name === activeWorkspace?.name ? { iconName: 'BoxCheckmarkSolid' } : undefined,
          onClick: () => activateWorkspace(w.name || ''),
        })) || [],
      [activateWorkspace, workspaces, activeWorkspace]
    ),
    [items, setItems] = React.useState(menuItems),
    linkClickHandler = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
      const { key, url } = item || {};
      if (!ev || !key || !url) return;
      ev.preventDefault(); // Prevents page reload.
      setSelectedKey(key);
      history.push(url);
    },
    sideNavRoutes = React.useMemo(
      () => [
        {
          key: 'home',
          name: 'Home',
          url: `/orchestrator/${activeWorkspace?.name}`,
          icon: 'Home',
        },
        {
          key: 'runnables',
          name: 'Runnables',
          url: `/orchestrator/${activeWorkspace?.name}${ENDPOINTS.RUNNABLES}`,
          icon: 'Rocket',
        },
        {
          key: 'workflows',
          name: 'Workflows',
          url: `/orchestrator/${activeWorkspace?.name}${ENDPOINTS.WORKFLOWS}`,
          icon: 'Flow',
        },
        {
          key: 'executions',
          name: 'Active executions',
          url: `/orchestrator/${activeWorkspace?.name}${ENDPOINTS.ACTIVE_EXECUTIONS}`,
          icon: 'Play',
        },
      ],
      [activeWorkspace?.name]
    ),
    onAbort = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    onChange = React.useCallback(
      (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === undefined) return;
        const filteredItems = menuItems.filter(
          (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
        );

        if (!filteredItems || !filteredItems.length) {
          filteredItems.push({
            key: 'no_results',
            onRender: (_item, _dismissMenu) => (
              <div key="no_results" style={filteredItemsStyle}>
                <Icon iconName="SearchIssue" title="No actions found" />
                <span>No workspace found</span>
              </div>
            ),
          });
        }

        setItems(filteredItems);
      },
      [menuItems]
    ),
    onKeyDown = React.useCallback((e) => {
      /* Key Up, but we are not at the beginning of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart > 0 && e.which === KeyCodes.up) {
        e.stopPropagation();
      }
      /* Key Down, but we are not at the end of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart !== e.target.value.length && e.which === KeyCodes.down) {
        e.stopPropagation();
      }
    }, []),
    onDismiss = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    renderMenuList = React.useCallback(
      (menuListProps?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        return (
          <div className={classNames.contextualMenu}>
            <div style={{ borderBottom: '1px solid #ccc' }}>
              <SearchBox
                ariaLabel="Filter workspaces by text"
                placeholder="Filter workspaces"
                onAbort={onAbort}
                onChange={onChange}
                onKeyDown={onKeyDown}
                styles={searchBoxStyles}
              />
            </div>
            {defaultRender?.(menuListProps)}
            <br />
            <Link
              style={{ marginLeft: 12 }}
              onClick={() => {
                history.push('/orchestrator/workspaces');
                setSelectedKey('');
              }}
            >
              Show all workspaces
            </Link>
          </div>
        );
      },
      [onAbort, onChange, onKeyDown]
    ),
    menuProps = React.useMemo(
      () => ({
        onRenderMenuList: renderMenuList,
        shouldFocusOnMount: true,
        items,
        focusZoneProps: {
          /* Allow up and down arrows to move focus out of the SearchBox */
          shouldInputLoseFocusOnArrowKey: () => true,
        },
        onDismiss,
      }),
      [items, renderMenuList, onDismiss]
    );

  React.useEffect(() => {
    const newItems: IContextualMenuItem[] =
      workspaces?.map((w) => ({
        key: w.name || '',
        text: w.displayName,
        iconProps: w.name === activeWorkspace?.name ? { iconName: 'BoxCheckmarkSolid' } : undefined,
        onClick: () => activateWorkspace(w.name || ''),
      })) || [];
    setItems(newItems);
  }, [workspaces, activeWorkspace, activateWorkspace]);

  React.useEffect(() => {
    const afterWorkspaces = history.location.pathname?.split('workspaces/')?.[1],
      item = afterWorkspaces?.split('/')?.[1],
      keyMap = {
        workflows: 'workflows',
        runnables: 'runnables',
        activeExecutions: 'executions',
        default: afterWorkspaces?.split('/')?.length >= 1 ? 'home' : '',
      },
      newKey = keyMap[item] ?? keyMap.default;

    if (selectedKey !== newKey) setSelectedKey(newKey);
  }, [history.location.pathname]);

  return (
    <>
      <Nav groups={[{ links: sideNavRoutes }]} onLinkClick={linkClickHandler} selectedKey={selectedKey} />
      <div className={classNames.workspaceWidget}>
        <h4 className={classNames.widgetTitle}>Active workspace</h4>
        <Button
          iconName="Stack"
          text={activeWorkspace?.displayName || 'No workspace selected'}
          menuIconName="ChevronDown"
          menuProps={menuProps}
          styles={{ root: { width: '226px' } }}
        />
        <Button
          text="Access control"
          onClick={() => {
            // TODO: Implement access control side panel.
          }}
          iconName="AddGroup"
          styles={accessControlStyles}
          buttonContainerStyles={{ root: { marginTop: 12, textAlign: 'center' } }}
        />
      </div>
    </>
  );
};

const NavigationWrapper: React.FC = ({ children }) => {
  const setLeftPanelProps = useLeftPanel(),
    { ACTIVE_WORKSPACE_NAME, workspaces, activateWorkspace, activeWorkspace } = useWorkspaces(),
    theme = useTheme(),
    classNames = useClassNames<INavigationWrapperStyles, ClassNamesFromIStyles<INavigationWrapperStyles>>(
      'navigationWrapper',
      navigationWrapperStyles
    );

  React.useEffect(() => {
    if (!ACTIVE_WORKSPACE_NAME) return;
    setLeftPanelProps({
      content: (
        <Navigation activeWorkspace={activeWorkspace} workspaces={workspaces} activateWorkspace={activateWorkspace} />
      ),
      styles: {
        root: {
          padding: 12,
          height: 'calc(100% - 156px)', // 96 header height + 60 footer height = 156
          backgroundColor: theme.semanticColors?.contentBackground,
          boxShadow: '6px 0px 10px -6px var(--h2o-gray400_a_16, #1C1D2129)',
        },
      },
    });
    return () => setLeftPanelProps({ content: undefined });
  }, [setLeftPanelProps, ACTIVE_WORKSPACE_NAME, activateWorkspace, workspaces, activeWorkspace]);

  return (
    <div className={classNames.container}>
      <Stack styles={stackStylesPage} className={classNames.stack}>
        {children}
      </Stack>
    </div>
  );
};

export default NavigationWrapper;
