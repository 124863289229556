import { GetWorkflowResponseFixed, ListWorkflowsResponseFixed } from '../pages/Orchestrator/WorkflowTabCanvas';
import { ListWorkflowExecutionsResponseFixed } from '../pages/Orchestrator/WorkflowTabExecutions';
import { fetchWrapRPC } from '../services/api';
import {
  ExecutorPoolService_ListExecutorPools,
  ListExecutorPoolsRequest,
} from './gen/ai/h2o/orchestrator/v1/executor_pool_service_pb';
import {
  GetPreferencesRequest,
  PreferencesService_GetPreferences,
  PreferencesService_UpdatePreferences,
  UpdatePreferencesRequest,
} from './gen/ai/h2o/orchestrator/v1/preferences_service_pb';
import {
  CreateRunnableRequest,
  DeleteRunnableRequest,
  ListRunnablesRequest,
  RunnableService_CreateRunnable,
  RunnableService_DeleteRunnable,
  RunnableService_ListRunnables,
  RunnableService_UpdateRunnable,
  UpdateRunnableRequest,
} from './gen/ai/h2o/orchestrator/v1/runnable_service_pb';
import {
  CancelWorkflowExecutionRequest,
  DeleteWorkflowExecutionRequest,
  ListWorkflowExecutionsRequest,
  WorkflowExecutionService_CancelWorkflowExecution,
  WorkflowExecutionService_DeleteWorkflowExecution,
  WorkflowExecutionService_ListWorkflowExecutions,
} from './gen/ai/h2o/orchestrator/v1/workflow_execution_service_pb';
import {
  CreateWorkflowRequest,
  DeleteWorkflowRequest,
  ExecuteWorkflowRequest,
  GetWorkflowRequest,
  ListWorkflowsRequest,
  UpdateWorkflowRequest,
  WorkflowService_CreateWorkflow,
  WorkflowService_DeleteWorkflow,
  WorkflowService_ExecuteWorkflow,
  WorkflowService_GetWorkflow,
  WorkflowService_ListWorkflows,
  WorkflowService_UpdateWorkflow,
} from './gen/ai/h2o/orchestrator/v1/workflow_service_pb';
import { RPC } from './gen/runtime';

export class OrchestratorServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async getRunnables(req: ListRunnablesRequest) {
    return await fetchWrapRPC(RunnableService_ListRunnables, { basePath: this.basePath })({ ...req });
  }

  public async createRunnable(req: CreateRunnableRequest) {
    return await fetchWrapRPC(RunnableService_CreateRunnable, { basePath: this.basePath })({ ...req });
  }

  public async editRunnable(req: UpdateRunnableRequest) {
    return await fetchWrapRPC(RunnableService_UpdateRunnable, { basePath: this.basePath })({ ...req });
  }

  public async deleteRunnable(req: DeleteRunnableRequest) {
    return await fetchWrapRPC(RunnableService_DeleteRunnable, { basePath: this.basePath })({ ...req });
  }

  public async getWorkflows(req: ListWorkflowsRequest) {
    // TODO: Replace type once generated ts api is fixed.
    return await fetchWrapRPC(WorkflowService_ListWorkflows as RPC<ListWorkflowsRequest, ListWorkflowsResponseFixed>, {
      basePath: this.basePath,
    })({ ...req });
  }

  public async getWorkflow(req: GetWorkflowRequest) {
    // TODO: Replace type once generated ts api is fixed.
    return await fetchWrapRPC(WorkflowService_GetWorkflow as RPC<GetWorkflowRequest, GetWorkflowResponseFixed>, {
      basePath: this.basePath,
    })({ ...req });
  }

  public async executeWorkflow(req: ExecuteWorkflowRequest) {
    return await fetchWrapRPC(WorkflowService_ExecuteWorkflow, { basePath: this.basePath })({ ...req });
  }

  public async createWorkflow(req: CreateWorkflowRequest) {
    return await fetchWrapRPC(WorkflowService_CreateWorkflow, { basePath: this.basePath })({ ...req });
  }

  public async editWorkflow(req: UpdateWorkflowRequest) {
    return await fetchWrapRPC(WorkflowService_UpdateWorkflow, { basePath: this.basePath })({ ...req });
  }

  public async deleteWorkflow(req: DeleteWorkflowRequest) {
    return await fetchWrapRPC(WorkflowService_DeleteWorkflow, { basePath: this.basePath })({ ...req });
  }

  public async getExecutorPools(req: ListExecutorPoolsRequest) {
    return await fetchWrapRPC(ExecutorPoolService_ListExecutorPools, { basePath: this.basePath })({ ...req });
  }

  public async getPreferences(req: GetPreferencesRequest) {
    return await fetchWrapRPC(PreferencesService_GetPreferences, { basePath: this.basePath })({ ...req });
  }

  public async updatePreferences(req: UpdatePreferencesRequest) {
    return await fetchWrapRPC(PreferencesService_UpdatePreferences, { basePath: this.basePath })({ ...req });
  }
  // TODO: Replace with 'ListWorkflowExecutionsResponse' type once generated ts api is fixed.
  public async getWorkflowExecutions(req: ListWorkflowExecutionsRequest) {
    return await fetchWrapRPC(
      WorkflowExecutionService_ListWorkflowExecutions as RPC<
        ListWorkflowExecutionsRequest,
        ListWorkflowExecutionsResponseFixed
      >,
      { basePath: this.basePath }
    )({ ...req });
  }

  public async deleteWorkflowExecution(req: DeleteWorkflowExecutionRequest) {
    return await fetchWrapRPC(WorkflowExecutionService_DeleteWorkflowExecution, { basePath: this.basePath })({
      ...req,
    });
  }

  public async cancelWorkflowExecution(req: CancelWorkflowExecutionRequest) {
    return await fetchWrapRPC(WorkflowExecutionService_CancelWorkflowExecution, { basePath: this.basePath })({
      ...req,
    });
  }
}
