import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { GetEnvironmentDataResponse } from '../ai.h2o.cloud.appstore';
import { Engine_Type } from '../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { useEnv, useUser } from '../utils/hooks';
import AdminAIEnginesPage from './AdminAIEnginesPage';
import AdminAppsPage from './AdminAppsPage';
import AdminInstancePage from './AdminInstancePage';
import AdminManageAliasPage from './AdminManageAliasPage';
import AdminManageSecretsPage from './AdminManageSecretsPage';
import { AdminMarketplaceAppDetailPage } from './AdminMarketplace/AdminMarketplaceAppDetailPage/AdminMarketplaceAppDetailPage';
import AdminMarketplaceAppListPage from './AdminMarketplace/AdminMarketplaceAppListPage/AdminMarketplaceAppListPage';
import AIEMAdminSettingsPage from './AIEngineSettingsPage/AIEMAdminSettingsPage';
import AppAlias from './AppAlias/AppAlias';
import AppDetailPage from './AppDetailsPage/AppDetailPage';
import AppInstanceLogPage from './AppInstanceLogPage/AppInstanceLogPage';
import AppStorePage from './AppStorePage/AppStorePage';
import CliAndApiAccessPage from './CliAndApiAccessPage/CliAndApiAccessPage';
import { Error401Page, Error404Page, Error500Page, NotFoundPage } from './ErrorPage';
import { HomePage } from './HomePage/HomePage';
import InstancePage from './InstancePage';
import LegacyEngineLogPage from './LegacyEngineLogPage/LegacyEngineLogPage';
import LoggingServicesPage from './LoggingServicesPage/LoggingServicesPage';
import MyAIEnginesPage from './MyAIEnginesPage';
import MyAppsPage from './MyAppsPage';
import ActiveExecutions from './Orchestrator/ActiveExecutions';
import Dashboard from './Orchestrator/Dashboard';
import RunnableDetail from './Orchestrator/RunnableDetail';
import Runnables from './Orchestrator/Runnables';
import WorkflowDetail from './Orchestrator/WorkflowDetail';
import Workflows from './Orchestrator/Workflows';
import { WorkspaceProvider } from './Orchestrator/WorkspaceProvider';
import WorkspaceRedirect from './Orchestrator/WorkspaceRedirect';
import Workspaces from './Orchestrator/Workspaces';
import PlatformUsage from './PlatformUsage/PlatformUsage';

export enum RoutePaths {
  ROOT = '/',
  HOME = '/home',
  APPSTORE = '/appstore',
  APP = '/apps/:id',
  MYAPPS = '/myapps',
  INSTANCES = '/instances',
  INSTANCELOG = '/instances/log/:instanceId',
  PLATFORM_USAGE = '/platform-usage',
  ADMIN_APPS = '/admin/apps',
  ADMIN_ALIASES = '/admin/aliases',
  ADMIN_INSTANCES = '/admin/instances',
  ADMIN_INSTANCELOG = '/admin/instances/log/:instanceId',
  ADMIN_MARKETPLACE_APPS = '/admin/marketplace/apps',
  ADMIN_MARKETPLACE_APP = '/admin/marketplace/apps/:id',
  ADMIN_SECRETS = '/admin/secrets',
  CLI_AND_API_ACCESS = '/cli-and-api-access',
  MY_AI_ENGINES = '/aiengines',
  ADMIN_AI_ENGINES = '/admin/aiengines',
  AI_ENGINE_SETTINGS = '/admin/aiengines/settings',
  LEGACY_ENGINE_LOG = '/aiengines/log/:engineType/:engineName',
  LEGACY_ADMIN_ENGINE_LOG = '/admin/aiengines/log/:engineType/:engineName',
  LOGGING_SERVICES = '/logs/',
  ERROR_401 = '/401',
  ERROR_404 = '/404',
  ERROR_500 = '/500',
  ERROR_NOT_FOUND = '/not_found',
  APP_ALIAS = '/v1/latestapp/:appName',
  ORCHESTRATOR = '/orchestrator/workspaces/:workspace_id',
  ORCHESTRATOR_WORKFLOWS = '/orchestrator/workspaces/:workspace_id/workflows',
  ORCHESTRATOR_WORKFLOW_DETAIL = '/orchestrator/workspaces/:workspace_id/workflows/:workflow_id/:tab_id?/:item_name?',
  ORCHESTRATOR_RUNNABLES = '/orchestrator/workspaces/:workspace_id/runnables',
  ORCHESTRATOR_RUNNABLE_DETAIL = '/orchestrator/workspaces/:workspace_id/runnables/:runnable_id',
  ORCHESTRATOR_WORKSPACE_SELECTION = '/orchestrator/workspaces',
  ORCHESTRATOR_ACTIVE_EXECUTIONS = '/orchestrator/workspaces/:workspace_id/activeExecutions',
}

export type PageRoute = {
  path?: RoutePaths;
  exact: boolean;
  public: boolean;
  render: (m: any) => JSX.Element;
};

export const defaultRoutes: PageRoute[] = [
  {
    path: RoutePaths.APPSTORE,
    exact: true,
    public: true,
    render: () => <AppStorePage />,
  },
  {
    path: RoutePaths.APP,
    exact: true,
    public: true,
    render: ({ match }: RouteComponentProps<{ id: string }>) => <AppDetailPage id={match.params.id} />,
  },
  {
    path: RoutePaths.MYAPPS,
    exact: true,
    public: false,
    render: () => <MyAppsPage />,
  },
  {
    path: RoutePaths.INSTANCES,
    exact: true,
    public: false,
    render: () => <InstancePage />,
  },
  {
    path: RoutePaths.INSTANCELOG,
    exact: true,
    public: false,
    render: ({ match }: RouteComponentProps<{ instanceId: string }>) => (
      <AppInstanceLogPage instanceId={match.params.instanceId} />
    ),
  },
  {
    path: RoutePaths.ADMIN_APPS,
    exact: true,
    public: false,
    render: () => <AdminAppsPage />,
  },
  {
    path: RoutePaths.ADMIN_ALIASES,
    exact: true,
    public: false,
    render: () => <AdminManageAliasPage />,
  },
  {
    path: RoutePaths.ADMIN_INSTANCES,
    exact: true,
    public: false,
    render: () => <AdminInstancePage />,
  },
  {
    path: RoutePaths.ADMIN_INSTANCELOG,
    exact: true,
    public: false,
    render: ({ match }: RouteComponentProps<{ instanceId: string }>) => (
      <AppInstanceLogPage instanceId={match.params.instanceId} />
    ),
  },
  {
    path: RoutePaths.ADMIN_MARKETPLACE_APPS,
    exact: true,
    public: false,
    render: () => <AdminMarketplaceAppListPage />,
  },
  {
    path: RoutePaths.ADMIN_MARKETPLACE_APP,
    exact: true,
    public: false,
    render: ({ match }: RouteComponentProps<{ id: string }>) => <AdminMarketplaceAppDetailPage id={match.params.id} />,
  },
  {
    path: RoutePaths.ADMIN_SECRETS,
    exact: true,
    public: false,
    render: () => <AdminManageSecretsPage />,
  },
  {
    path: RoutePaths.CLI_AND_API_ACCESS,
    exact: true,
    public: false,
    render: () => <CliAndApiAccessPage />,
  },
  {
    path: RoutePaths.MY_AI_ENGINES,
    exact: true,
    public: false,
    render: () => <MyAIEnginesPage />,
  },
  {
    path: RoutePaths.ADMIN_AI_ENGINES,
    exact: true,
    public: false,
    render: () => <AdminAIEnginesPage />,
  },
  {
    path: RoutePaths.AI_ENGINE_SETTINGS,
    exact: true,
    public: false,
    render: () => <AIEMAdminSettingsPage />,
  },
  {
    path: RoutePaths.APP_ALIAS,
    exact: false,
    public: false,
    render: ({ match }: RouteComponentProps<{ appName: string }>) => <AppAlias appName={match.params.appName} />,
  },
  {
    path: RoutePaths.LEGACY_ENGINE_LOG,
    exact: true,
    public: false,
    render: ({ match }: RouteComponentProps<{ engineName: string; engineType: Engine_Type }>) => (
      <LegacyEngineLogPage engineName={match.params.engineName} engineType={match.params.engineType} />
    ),
  },
  {
    path: RoutePaths.LEGACY_ADMIN_ENGINE_LOG,
    exact: true,
    public: false,
    render: ({ match }: RouteComponentProps<{ engineName: string; engineType: Engine_Type }>) => (
      <LegacyEngineLogPage engineName={match.params.engineName} engineType={match.params.engineType} />
    ),
  },
  {
    path: RoutePaths.LOGGING_SERVICES,
    exact: true,
    public: false,
    render: () => <LoggingServicesPage />,
  },
  {
    path: RoutePaths.ERROR_401,
    exact: true,
    public: true,
    render: () => <Error401Page />,
  },
  {
    path: RoutePaths.ERROR_500,
    exact: true,
    public: true,
    render: () => <Error500Page />,
  },
  {
    path: RoutePaths.ERROR_NOT_FOUND,
    exact: true,
    public: true,
    render: () => <NotFoundPage />,
  },
  {
    exact: false,
    public: true,
    render: () => <Error404Page />,
  },
];

// Separate routes for orchestrator to allow workspace provider.
// TODO: Add into defaultRoutes once workspace switching is on the global level.
const orchestratorRoutes: PageRoute[] = [
  {
    path: RoutePaths.ORCHESTRATOR,
    exact: true,
    public: false,
    render: () => <Dashboard />,
  },
  {
    path: RoutePaths.ORCHESTRATOR_WORKFLOWS,
    exact: true,
    public: false,
    render: () => <Workflows />,
  },
  {
    path: RoutePaths.ORCHESTRATOR_WORKFLOW_DETAIL,
    exact: true,
    public: false,
    render: () => <WorkflowDetail />,
  },
  {
    path: RoutePaths.ORCHESTRATOR_RUNNABLES,
    exact: true,
    public: false,
    render: () => <Runnables />,
  },
  {
    path: RoutePaths.ORCHESTRATOR_RUNNABLE_DETAIL,
    exact: true,
    public: false,
    render: () => <RunnableDetail />,
  },
  {
    path: RoutePaths.ORCHESTRATOR_ACTIVE_EXECUTIONS,
    exact: true,
    public: false,
    render: () => <ActiveExecutions />,
  },
  {
    path: RoutePaths.ORCHESTRATOR_WORKSPACE_SELECTION,
    exact: true,
    public: false,
    render: () => <Workspaces />,
  },
  {
    exact: true,
    public: true,
    render: () => <Error404Page />,
  },
];

export function getRoutes(
  env: GetEnvironmentDataResponse,
  routes: PageRoute[],
  publicRoutesOnly: boolean
): PageRoute[] {
  let newRoutes = routes.slice();
  if (env?.homePage?.enabled) {
    newRoutes.unshift({
      path: RoutePaths.HOME,
      exact: true,
      public: false,
      render: () => <HomePage />,
    });
    newRoutes.unshift({
      path: RoutePaths.ROOT,
      exact: true,
      public: false,
      render: () => <Redirect to={RoutePaths.HOME} />,
    });
  } else {
    newRoutes.unshift({
      path: RoutePaths.ROOT,
      exact: true,
      public: false,
      render: () => <Redirect to={RoutePaths.APPSTORE} />,
    });
  }
  if (env?.platformUsageEnabled) {
    newRoutes.unshift({
      path: RoutePaths.PLATFORM_USAGE,
      exact: true,
      public: false,
      render: () => <PlatformUsage />,
    });
  }
  if (publicRoutesOnly) {
    newRoutes.unshift({
      path: RoutePaths.ROOT,
      exact: true,
      public: true,
      render: () => <Redirect to={RoutePaths.APPSTORE} />,
    });
    newRoutes = newRoutes.filter((r) => r.public);
  }
  return newRoutes;
}

export function Routes() {
  // feature flags for certain routes depends on the env service:
  const env = useEnv(),
    user = useUser(),
    publicRoutesOnly = !!env?.publicModeEnabled && !user.hasFullAccess;
  if (!env) {
    return null;
  }
  const routes = getRoutes(env, defaultRoutes, publicRoutesOnly);
  return (
    <Switch>
      <Route path="/orchestrator">
        <WorkspaceProvider>
          <Switch>
            <Route exact path="/orchestrator" render={() => <WorkspaceRedirect />} />
            {orchestratorRoutes.map((r, index) => (
              <Route key={`orchestrator-route-${index}`} exact={r.exact} path={r.path} render={r.render} />
            ))}
          </Switch>
        </WorkspaceProvider>
      </Route>
      {routes.map((r, index) => (
        <Route key={`route-${index}`} path={r.path} exact={r.exact} render={r.render} />
      ))}
    </Switch>
  );
}
