import { IStyle } from '@fluentui/react';
import { IH2OTheme, Sizes, useClassNames, useTheme } from '@h2oai/ui-kit';
import { Monaco, Editor as MonacoEditor } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import React from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';

type Props = {
  language: string;
  defaultCode?: string;
  error?: string;
  onCodeChange: (code: string) => void;
};

interface IEditorStyles {
  editorContainer: IStyle;
  editor: IStyle;
}

const editorStyles = (theme: IH2OTheme): IEditorStyles => {
  return {
    editorContainer: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
    },
    editor: {
      position: 'absolute',
      inset: 0,
      backgroundColor: '#000000',
      padding: 8,
      paddingTop: 15,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.semanticColors?.inputBorder,
      borderRadius: Sizes.borderRadius,
    },
  };
};

// TODO: Handle "error" prop - show error message when submitting empty fields.
const Editor: React.VFC<Props> = ({ language = 'python', defaultCode = '', onCodeChange }) => {
  const theme = useTheme(),
    classNames = useClassNames<IEditorStyles, ClassNamesFromIStyles<IEditorStyles>>('editor', editorStyles(theme)),
    monacoEl = React.useRef<editor.IStandaloneCodeEditor | null>(null),
    timeoutRef = React.useRef<number | null>(null),
    handleEditorBeforeMount = (monaco: Monaco) => {
      monaco.editor.defineTheme('custom-theme', {
        base: 'vs-dark',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': '#000000',
        },
      });
    },
    handleEditorDidMount = (editor: editor.IStandaloneCodeEditor, _monaco: Monaco) => (monacoEl.current = editor),
    handleEditorChange = (value: string | undefined, _ev: editor.IModelContentChangedEvent) => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => onCodeChange(value || ''), 800);
    };

  return (
    <div className={classNames.editorContainer}>
      <MonacoEditor
        language={language}
        defaultValue={defaultCode}
        beforeMount={handleEditorBeforeMount}
        onMount={handleEditorDidMount}
        onChange={handleEditorChange}
        wrapperProps={{ className: classNames.editor }}
        theme="custom-theme"
        options={{
          automaticLayout: true,
        }}
      />
    </div>
  );
};

export default Editor;
