import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useOrchestratorService } from '../../orchestrator/hooks';
import { FailedToLoadView } from './FailedToLoadView';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { NoItemView } from './NoItemView';
import WidgetList from './WidgetList';
import { WorkflowFixed } from './WorkflowTabCanvas';
import { WorkflowExecutionItem, calculateDuration, executionColumns } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

const extractWorkflowName = (workflowExecution?: string) =>
  workflowExecution?.includes('/executions/') ? workflowExecution.split('/executions/')?.[0] : undefined;

const ActiveExecutions = () => {
  const history = useHistory(),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    [loading, setLoading] = React.useState(true),
    [activeExecutionsItems, setActiveExecutionsItems] = React.useState<WorkflowExecutionItem[]>(),
    [workflows, setWorkflows] = React.useState<WorkflowFixed[]>(),
    cancelExecution = async (executionName: string) => {
      setLoading(true);
      try {
        await orchestratorService.cancelWorkflowExecution({ name: executionName });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Execution cancelled successfully',
        });
        void fetchActiveExecutions();
      } catch (err) {
        const message = `Failed to cancel execution: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoading(false);
      }
    },
    fetchActiveExecutions = React.useCallback(async () => {
      setLoading(true);
      try {
        const data = await orchestratorService.getWorkflowExecutions({
          parent: ACTIVE_WORKSPACE_NAME || '',
          filter: "state='STATE_RUNNING'",
        });
        const items = data?.workflowExecutions
          ? data.workflowExecutions.map((item) => ({
              ...item,
              workflowDisplayName:
                workflows?.find((w) => w.name === extractWorkflowName(item.name))?.displayName ||
                extractWorkflowName(item.name) ||
                item.name,
              // TODO: Add user/workflow name once the API provides initiatorDisplayName prop.
              runBy: item.initiator
                ? `${item.initiator.includes('/workflows') ? 'Triggered' : 'Manually run'} by ${item.initiator}`
                : '(Unknown)',
              duration: calculateDuration(item.startTime, item.endTime),
              onView: () => history.push(`/orchestrator/${item.name}`),
              onCancel: () => void cancelExecution(item.name || ''),
            }))
          : undefined;
        setActiveExecutionsItems(items);
      } catch (err) {
        const message = `Failed to fetch workflow executions: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setActiveExecutionsItems(undefined);
      } finally {
        setLoading(false);
      }
    }, [ACTIVE_WORKSPACE_NAME, addToast, history, orchestratorService, workflows]),
    fetchWorkflows = async () => {
      setLoading(true);
      try {
        const data = await orchestratorService.getWorkflows({ parent: ACTIVE_WORKSPACE_NAME || '' });
        setWorkflows(data?.workflows);
        setActiveExecutionsItems((items) =>
          items?.map((item) => ({
            ...item,
            workflowDisplayName:
              data?.workflows?.find((w) => w.name === extractWorkflowName(item.name))?.displayName ||
              extractWorkflowName(item.name) ||
              '(Unknown)',
          }))
        );
      } catch (err) {
        const message = `Failed to fetch workflows: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setWorkflows(undefined);
      } finally {
        setLoading(false);
      }
    };

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchWorkflows();
      void fetchActiveExecutions();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  return (
    <NavigationWrapper>
      {activeExecutionsItems?.length && !loading ? <Header /> : null}
      <WidgetList
        columns={executionColumns}
        items={activeExecutionsItems}
        loading={loading}
        NoItemsContent={NoItemView({
          title: 'No active executions',
          description: 'There are no currently running workflows in this workspace.',
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load active executions',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchActiveExecutions,
          actionIcon: 'Refresh',
        })}
      />
    </NavigationWrapper>
  );
};

export default ActiveExecutions;
