import { IconNamesInput } from '@fluentui/font-icons-mdl2/lib/IconNames';
import { IContextualMenuItem, concatStyleSets } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  IconName,
  buttonStylesSplit,
  buttonStylesSplitDanger,
  buttonStylesSplitGhost,
  buttonStylesSplitPrimary,
  buttonStylesSplitSecondary,
  compareVersionString,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerButtonSecondary,
  buttonStylesSplitSmall as uiKitButtonStylesSplitSmall,
  useTheme,
} from '@h2oai/ui-kit';

import { hasOp } from '../../../../aiem/engine/conditions';
import { useEngine } from '../../../../aiem/engine/hooks';
import { AIEMOpType, AIEngine, EngineState } from '../../../../aiem/engine/types';
import { isTransitionalState } from '../../../../aiem/engine/utils';
import { DAIEngine_State } from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_pb';
import { Engine_State, Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { useCloudPlatformDiscovery } from '../../../../utils/hooks';

export interface EngineActionButtonProps {
  isAdmin?: boolean;
  engine: AIEngine;
  viewEngine?: (engine: AIEngine) => void;
  editEngine?: (engine: AIEngine) => void;
  deleteEngine?: (engine: AIEngine) => void;
  pauseEngine?: (engine: AIEngine) => void;
  terminateEngine?: (engine: AIEngine) => void;
  resumeEngine?: (engine: AIEngine) => void;
  updateEngine?: (engine: AIEngine) => void;
  upgradeEngine?: (engine: AIEngine) => void;
  showResizeModal?: (engine: AIEngine) => void;
  migrateCreator?: (engine: AIEngine) => void;
  readLogs?: (engine: AIEngine) => void;
  openLegacyEngineLogs?: (engine: AIEngine) => void;
}

const canResume = (type: Engine_Type, state: EngineState) => hasOp(AIEMOpType.resume, type, state);

const buttonStylesSplitSmall = concatStyleSets(uiKitButtonStylesSplitSmall, { root: { width: 125 } });

export function EngineActionButton(props: EngineActionButtonProps) {
  const { palette } = useTheme(),
    { EngineStateMap } = useEngine(),
    platformDiscovery = useCloudPlatformDiscovery(),
    hasLoggingService = Boolean(platformDiscovery?.loggingServicesApiUrl),
    getMenuItems = ({
      isAdmin,
      engine,
      viewEngine,
      editEngine,
      deleteEngine,
      pauseEngine,
      upgradeEngine,
      showResizeModal,
      migrateCreator,
      readLogs,
      openLegacyEngineLogs,
    }: EngineActionButtonProps): IContextualMenuItem[] => {
      const hasMenuItem = (op: AIEMOpType) => hasOp(op, engineType!, enumState!),
        menuItems: IContextualMenuItem[] = [],
        style = { color: palette?.gray900 };
      const { upgradeAvailable } = engine;
      if (engineType === Engine_Type.UNSPECIFIED) return [];
      if (hasMenuItem(AIEMOpType.view)) {
        menuItems.push({
          key: 'view',
          text: 'View Details',
          onClick: () => viewEngine!(engine),
          style,
        });
      }
      if (hasMenuItem(AIEMOpType.edit)) {
        menuItems.push({
          key: 'edit',
          text: 'Edit',
          onClick: () => editEngine!(engine),
          style,
        });
      }
      if (hasMenuItem(AIEMOpType.pause) && enumState !== String(Engine_State.RUNNING)) {
        menuItems.push({
          key: 'pause',
          text: 'Pause',
          onClick: () => pauseEngine!(engine),
          style,
        });
      }
      if (
        isAdmin &&
        engineType === Engine_Type.DRIVERLESS_AI &&
        enumState === String(Engine_State.PAUSED) &&
        compareVersionString(engine.version, '1.10.5') > -1
      ) {
        menuItems.push({
          key: 'migrateCreator',
          text: 'Migrate Creator',
          onClick: () => migrateCreator!(engine),
          style,
        });
      }
      if (hasLoggingService) {
        menuItems.push({
          key: 'readLogs',
          text: 'Logs',
          onClick: () => readLogs!(engine),
        });
      } else if (hasMenuItem(AIEMOpType.openLog)) {
        menuItems.push({
          key: 'legacyReadLogs',
          text: 'Logs',
          onClick: () => openLegacyEngineLogs!(engine),
        });
      }
      if (hasMenuItem(AIEMOpType.upgrade) && upgradeAvailable) {
        menuItems.push({
          key: 'upgrade',
          text: 'Upgrade',
          onClick: () => upgradeEngine!(engine),
          style,
        });
      }
      if (hasMenuItem(AIEMOpType.resize) && !engine.storageResizing) {
        menuItems.push({
          key: 'resize',
          text: 'Resize',
          onClick: () => showResizeModal!(engine),
        });
      }
      if (hasMenuItem(AIEMOpType.delete)) {
        menuItems.push({
          key: 'delete',
          text: 'Delete',
          onClick: () => deleteEngine!(engine),
          style: { color: palette?.red500 },
        });
      }
      return menuItems;
    },
    { engine, viewEngine, resumeEngine, pauseEngine, terminateEngine } = props,
    { engineType, state: enumState, deprecatedVersion: deprecated } = engine,
    starting = String(enumState) === Engine_State.STARTING,
    stateData = enumState ? EngineStateMap.get(enumState) : undefined,
    { title } = stateData || { title: '' },
    styles = [buttonStylesSplitPrimary, buttonStylesSplitSmall],
    menuItems = getMenuItems(props),
    buttonProps: IButtonProps = {
      styles,
      split: true,
      menuIconName: IconName.ChevronDown,
      menuItems,
    },
    pauseButton = (
      <Button
        {...buttonProps}
        styles={[buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => pauseEngine!(engine)}
        iconName={IconName.Pause}
        text="Pause"
      />
    ),
    terminateButton = (
      <Button
        {...buttonProps}
        styles={[buttonStylesSplit, buttonStylesSplitDanger, buttonStylesSplitSmall]}
        onClick={() => terminateEngine!(engine)}
        iconName={'PowerButton' as IconNamesInput}
        text="Terminate"
      />
    ),
    resumeButton = (
      <Button
        {...buttonProps}
        onClick={() => resumeEngine!(engine)}
        primaryDisabled={deprecated}
        iconName={IconName.MSNVideos}
        text="Resume"
      />
    ),
    viewButton = (
      <Button
        {...buttonProps}
        menuItems={menuItems.filter((item) => item.key !== 'view')}
        styles={[buttonStylesSplit, buttonStylesSplitGhost, buttonStylesSplitSmall]}
        onClick={() => viewEngine!(engine)}
        iconName={'View' as IconNamesInput}
        text="View"
      />
    ),
    transitionalButton = (
      <Button
        menuItems={menuItems}
        split
        loaderWithMenuButton
        loading
        loaderProps={{
          label: title,
          styles: starting ? loaderStylesSpinnerButtonPrimary : loaderStylesSpinnerButtonSecondary,
        }}
        styles={[starting ? buttonStylesSplitPrimary : buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => {}}
      />
    );
  return isTransitionalState(enumState!)
    ? transitionalButton
    : canResume(engineType!, enumState!)
    ? resumeButton
    : enumState === String(DAIEngine_State.RUNNING) && engineType === Engine_Type.DRIVERLESS_AI
    ? pauseButton
    : enumState === String(DAIEngine_State.RUNNING) && engineType === Engine_Type.H2O
    ? terminateButton
    : viewButton;
}
