import { IStyle, mergeStyles } from '@fluentui/react';
import { IH2OTheme, useClassNames, useTheme } from '@h2oai/ui-kit';
import React from 'react';
import ReactFlow, {
  Background,
  Connection,
  Controls,
  Handle,
  Node,
  NodeMouseHandler,
  NodeTypes,
  Position,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from 'reactflow';

import 'reactflow/dist/style.css';
import { Runnable } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_pb';
import { Workflow, Workflow_WorkflowStep } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_pb';
import {
  GetWorkflowResponse,
  ListWorkflowsResponse,
} from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_service_pb';
import { ClassNamesFromIStyles } from '../../utils/models';
import { WORKFLOW } from './constants';
import { DropdownOption } from './RunnableDetail';
import WorkflowCanvasPanel, { IWorkflowCanvasPanel, RowData } from './WorkflowCanvasPanel';
import WorkflowCanvasWidget, { IWorkflowCanvasWidget } from './WorkflowCanvasWidget';
import { ContextMenu, ContextMenuProps } from './WorkflowContextMenu';

type ParamConfig = {
  [key: string]: string;
};

type SearchItem = {
  id: string;
  title: string;
  description: string;
  iconName: string;
};

// TODO: Replace these types once generated ts api is fixed.
type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
export type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (keyof T extends infer K ? (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K> : never) : never);
export type WorkflowStepFixed = Workflow_WorkflowStep & OneOf<{ runnable: string; workflow: string }>;
export type WorkflowFixed = Omit<Workflow, 'steps'> & { steps: WorkflowStepFixed[] };
export type ListWorkflowsResponseFixed = Omit<ListWorkflowsResponse, 'workflows'> & { workflows?: WorkflowFixed[] };
export type GetWorkflowResponseFixed = Omit<GetWorkflowResponse, 'workflow'> & { workflow: WorkflowFixed };

interface IWorkflowTabCanvas {
  nodeState: ReturnType<typeof useNodesState>;
  edgeState: ReturnType<typeof useEdgesState>;
  workflowName: string;
  onWorkflowNameChange: (newName: string) => void;
  defaultWorkflow?: WorkflowFixed;
  showValidation: boolean;
  timeout: string | null;
  concurrencyLimit: number;
  onConcurrencyLimitChange: (newValue: number) => void;
  onTimeoutChange: (newValue: string | null) => void;
  workflows?: WorkflowFixed[];
  runnables?: Runnable[];
  searchItemsNameMappings: { [key: string]: string };
  isSaved: boolean;
  setIsSaved: (isSaved: boolean) => void;
  loading: boolean;
}

interface IWorkflowCanvasTabStyles {
  rowContainer: IStyle;
  node: IStyle;
  dragNode: IStyle;
  dragNodeHint: IStyle;
  // TODO: Move label styles to classNames.
  // labelWrapper: IStyle;
  // typeBadge: IStyle;
  // labelTitle: IStyle;
}

export const getLabel = ({ displayName, runnable, workflow, altName }: WorkflowStepFixed & { altName?: string }) => (
  <div
    style={{
      padding: `${WORKFLOW.LABEL_PADDING_HORIZONTAL}px ${WORKFLOW.LABEL_PADDING_VERTICAL}px`,
      width: WORKFLOW.LABEL_WIDTH,
      height: WORKFLOW.LABEL_HEIGHT,
    }}
  >
    <div
      // TODO: Use theme colors.
      style={{
        backgroundColor: runnable !== undefined ? '#FEC925' : workflow !== undefined ? '#FF7A00' : '#0078D4',
        borderRadius: 4,
        fontSize: 11,
        padding: '0px 4px',
        width: 'fit-content',
      }}
    >
      {runnable !== undefined ? 'Runnable' : workflow !== undefined ? 'Workflow' : 'Step'}
    </div>
    <div
      style={{
        fontWeight: 700,
        fontSize: 14,
        paddingTop: 8,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'auto',
      }}
    >
      {displayName || altName || <br />}
    </div>
  </div>
);

const workflowCanvasTabStyles = (theme: IH2OTheme): IWorkflowCanvasTabStyles => {
    return {
      rowContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      node: {
        backgroundColor: theme.semanticColors?.contentBackground,
        padding: `${WORKFLOW.NODE_PADDING_VERTICAL}px ${WORKFLOW.NODE_PADDING_HORIZONTAL}px`,
        borderRadius: 8,
        boxSizing: 'content-box',
        borderCollapse: 'separate',
      },
      dragNode: {
        position: 'absolute',
        width: WORKFLOW.LABEL_WIDTH,
        height: WORKFLOW.LABEL_HEIGHT,
        backgroundColor: theme.semanticColors?.contentBackground,
        zIndex: 6, // React Flow panel is 5.
        borderRadius: 8,
        border: '1px dashed gray',
        borderTop: '6px solid #FEC925',
        cursor: 'grab',
        opacity: 0.6,
        $nest: {
          '&:hover': {
            opacity: 1,
          },
        },
      },
      dragNodeHint: {
        position: 'absolute',
        zIndex: 7,
        pointerEvents: 'none',
        maxWidth: WORKFLOW.LABEL_WIDTH,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'auto',
      },
    };
  },
  // TODO: Use theme.semanticColors?.buttonBorder.
  handleStyle = { width: 10, height: 10, backgroundColor: 'var(--h2o-gray500)' },
  CustomNode = ({ data, selected }: { data: any; selected: boolean }) => {
    const borderStyle = data.isError ? '2px dashed red' : '1px dashed gray';
    return (
      <>
        <Handle type="target" position={Position.Left} style={handleStyle} />
        <div
          style={{
            // TODO: Use classNames.node for following styles.
            padding: `${WORKFLOW.NODE_PADDING_VERTICAL}px ${WORKFLOW.NODE_PADDING_HORIZONTAL}px`,
            borderRadius: 8,
            boxSizing: 'content-box',
            borderCollapse: 'separate',
            // TODO: Use theme.palette?.yellow100 and theme.semanticColors?.contentBackground,
            backgroundColor: data.isActive || selected ? 'var(--h2o-yellow100)' : 'var(--h2o-white)',
            borderLeft: borderStyle,
            borderRight: borderStyle,
            borderBottom: borderStyle,
            borderTop: `6px solid ${data?.borderColor || '#FEC925'}`,
            cursor: data?.readonly ? 'pointer' : undefined,
          }}
        >
          {data?.label}
        </div>
        <Handle type="source" position={Position.Right} style={handleStyle} />
      </>
    );
  };

export const nodeTypes: NodeTypes = { custom: CustomNode };

export const WorkflowTabCanvas = ({
  nodeState,
  edgeState,
  workflowName,
  onWorkflowNameChange,
  defaultWorkflow,
  showValidation,
  timeout,
  concurrencyLimit,
  onConcurrencyLimitChange,
  onTimeoutChange,
  workflows,
  runnables,
  searchItemsNameMappings,
  isSaved,
  setIsSaved,
  loading,
}: IWorkflowTabCanvas) => {
  const theme = useTheme(),
    classNames = useClassNames<IWorkflowCanvasTabStyles, ClassNamesFromIStyles<IWorkflowCanvasTabStyles>>(
      'workflowCanvasTab',
      workflowCanvasTabStyles(theme)
    ),
    { screenToFlowPosition, getZoom } = useReactFlow(),
    store = useStoreApi(),
    reactFlowRef = React.useRef(null),
    [nodes, setNodes, onNodesChange] = nodeState,
    [edges, setEdges, onEdgesChange] = edgeState,
    [zoom, setZoom] = React.useState(1),
    [menu, setMenu] = React.useState<ContextMenuProps | null>(null),
    [isPanelOpen, setIsPanelOpen] = React.useState(false),
    [runnableItems, setRunnableItems] = React.useState<SearchItem[]>([]),
    [workflowItems, setWorkflowItems] = React.useState<SearchItem[]>([]),
    [searchItems, setSearchItems] = React.useState<SearchItem[]>([]),
    [activeNode, setActiveNode] = React.useState<Node>(),
    stepType = activeNode?.data.workflow !== undefined ? 'workflow' : 'runnable',
    [rowData, setRowData] = React.useState<RowData>({
      id: 'workflow-name',
      creator: defaultWorkflow?.creator,
      title: defaultWorkflow?.displayName || workflowName,
      creatorDisplayName: defaultWorkflow?.creatorDisplayName,
      createdAt: defaultWorkflow?.createTime,
      onTitleChange: onWorkflowNameChange,
      showValidation,
    }),
    updateActiveNodeData = React.useCallback(
      (data: Partial<Node['data']>) => {
        if (isSaved) setIsSaved(false);
        setActiveNode((node) => {
          if (!node) return node;
          return { ...node, data: { ...node.data, ...data } };
        });
      },
      [activeNode, isSaved]
    ),
    closeSidebar = () => {
      setIsPanelOpen(false);
      setActiveNode(undefined);
    },
    // TODO: Extend search logic to descriptions once available.
    onSearchChange = (searchText: string) =>
      setSearchItems(
        (stepType === 'runnable' ? runnableItems : workflowItems).filter((item) =>
          item.title.toLowerCase().includes(searchText.trim().toLocaleLowerCase())
        )
      ),
    onItemClick = (item: SearchItem) => {
      updateActiveNodeData({ [stepType]: item.id });
    },
    checkIfNatural = (numStr: string) => /^[0-9]+$/.test(numStr),
    onChangeConcurrencyLimit = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue && checkIfNatural(newValue)) onConcurrencyLimitChange(Number(newValue));
    },
    onChangeTimeout = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue && checkIfNatural(newValue)) onTimeoutChange(newValue);
      if (!newValue) onTimeoutChange(null);
    },
    onStepNameChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      updateActiveNodeData({ displayName: newValue || '' });
    },
    onDropdownChange = (_ev: React.FormEvent<HTMLDivElement>, option?: DropdownOption) => {
      if (option?.key === stepType) return;
      updateActiveNodeData({
        runnable: option?.key === 'runnable' ? '' : undefined,
        workflow: option?.key === 'workflow' ? '' : undefined,
      });
      setSearchItems(option?.key === 'runnable' ? runnableItems : workflowItems);
    },
    removeSelectedItem = () => {
      updateActiveNodeData({
        runnable: stepType === 'runnable' ? '' : undefined,
        workflow: stepType === 'workflow' ? '' : undefined,
      });
    },
    onUpdateConfig = (newConfig: ParamConfig) => {
      updateActiveNodeData({ parameters: newConfig });
    },
    activeNodeSelectedItem = React.useMemo(() => {
      return (stepType === 'workflow' ? workflowItems : runnableItems).find(
        (item) => item.id === (activeNode?.data.runnable || activeNode?.data.workflow)
      );
    }, [activeNode]),
    onNodesDelete = () => {
      if (isSaved) setIsSaved(false);
      setActiveNode(undefined);
      setIsPanelOpen(false);
    },
    onEdgesDelete = () => {
      if (isSaved) setIsSaved(false);
    },
    onConnect = React.useCallback(
      (params: Connection) => {
        if (isSaved) setIsSaved(false);
        setEdges((edges) => addEdge({ ...params, animated: true }, edges));
      },
      [setEdges]
    ),
    onNodeSelect: NodeMouseHandler = (_ev, node) => {
      setActiveNode({ ...node, data: { ...node.data, isActive: true } });
      setSearchItems(node?.data.workflow !== undefined ? workflowItems : runnableItems);
      setIsPanelOpen(true);
    },
    onCanvasClick = () => {
      setMenu(null);
      closeSidebar();
    },
    onEdgeSelect = () => {
      closeSidebar();
    },
    addNode = (x: number, y: number) => () => {
      if (isSaved) setIsSaved(false);
      // Temporary uniqueId for node manipulation. It is replaced on create/upadte workflow.
      const uniqueId = `new-step-${nodes.length}_${x}_${y}`;
      setNodes((nodes) => [
        ...nodes,
        {
          id: uniqueId,
          type: 'custom',
          position: { x, y },
          data: {
            uniqueId,
            displayName: `Step ${nodes.length + 1}`,
            label: getLabel({
              uniqueId,
              displayName: `Step ${nodes.length + 1}`,
              runnable: '',
            }),
            runnable: '',
            workflow: undefined,
            parameters: {},
            dependsOn: [],
            isError: showValidation,
            isActive: false,
          },
        },
      ]);
    },
    onPaneContextMenu = React.useCallback(
      (event: React.MouseEvent<Element, MouseEvent>) => {
        // Prevent native context menu from showing
        event.preventDefault();

        // TODO: Make sure context menu doesn't get positioned off-screen.
        if (!reactFlowRef?.current) return;
        const pane = (reactFlowRef.current as HTMLDivElement).getBoundingClientRect(),
          top = event.clientY - pane.top,
          left = event.clientX - pane.left,
          right = pane.width - (event.clientX - pane.left) - 120,
          bottom = pane.height - (event.clientY - pane.top) - 32,
          { x, y } = screenToFlowPosition({ x: event.clientX, y: event.clientY });

        setMenu({
          top,
          left,
          right,
          bottom,
          onPaneClick: onCanvasClick,
          onCreateClick: addNode(x, y),
        });
      },
      [setMenu, screenToFlowPosition]
    ),
    onNodeContextMenu = (e: React.MouseEvent<Element, MouseEvent>) => e.preventDefault(),
    onDragOver = (ev: React.DragEvent) => {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = 'copy';
    },
    onDrop = (ev: React.DragEvent) => {
      ev.preventDefault();
      const data = ev.dataTransfer.getData('text/plain');
      if (typeof data === undefined || !data) return;
      const { offsetX, offsetY } = JSON.parse(data);
      const { x, y } = screenToFlowPosition({ x: ev.clientX - offsetX, y: ev.clientY - offsetY });
      addNode(x, y)();
    },
    DragAndDropItem = React.useCallback(() => {
      return (
        <>
          <div
            className={mergeStyles(classNames.node, classNames.dragNode)}
            draggable
            onDragOver={(ev) => ev.preventDefault()}
            style={{
              zoom: zoom,
              bottom: 15 / zoom,
              left: 70 / zoom,
            }}
            onDragStart={(ev) => {
              setMenu(null);
              ev.dataTransfer.setData(
                'text/plain',
                JSON.stringify({
                  offsetX: ev.nativeEvent?.offsetX || 0,
                  // Adjust offset by 6 due to top border.
                  offsetY: ev.nativeEvent?.offsetY + 6 || 0,
                })
              );
            }}
          />
          <div
            className={classNames.dragNodeHint}
            style={{
              bottom: 15 + 27 / zoom,
              left: 20 + 70 / zoom,
              fontSize: 10 * zoom,
              zoom: zoom,
            }}
          >
            Drag&Drop to add a new node.
          </div>
        </>
      );
    }, [zoom]),
    widgetProps: IWorkflowCanvasWidget = {
      defaultWorkflow,
      workflowName,
      isSaved,
      rowData,
      timeout,
      onChangeTimeout,
      concurrencyLimit,
      onChangeConcurrencyLimit,
    },
    panelProps: IWorkflowCanvasPanel = {
      isPanelOpen,
      activeNode,
      stepType,
      activeNodeSelectedItem,
      showValidation,
      searchItems,
      onCloseSidebar: closeSidebar,
      onDropdownChange,
      onItemClick,
      removeSelectedItem,
      onSearchChange,
      onUpdateConfig,
      onStepNameChange,
    };

  React.useEffect(() => {
    store.setState({
      onViewportChange: () => {
        // TODO: Check if needs performance optimization.
        setZoom(getZoom());
        setMenu((menu) => {
          if (menu) return null;
          return menu;
        });
      },
    });
  }, [getZoom]);

  React.useEffect(() => {
    setRowData((data) => ({ ...data, title: workflowName }));
  }, [workflowName]);

  React.useEffect(() => {
    const items: SearchItem[] = (runnables || []).map((r) => ({
      title: r.displayName || '',
      id: r.name || '',
      description: 'Some description',
      iconName: 'ProjectCollection',
    }));
    setRunnableItems(items);
  }, [runnables]);

  React.useEffect(() => {
    const items: SearchItem[] = (workflows || []).map((w) => ({
      title: w.displayName || '',
      id: w.name || '',
      description: 'Some description',
      iconName: 'ProjectCollection',
    }));
    setWorkflowItems(items);
  }, [workflows]);

  React.useEffect(() => {
    setSearchItems(stepType === 'runnable' ? runnableItems : workflowItems);
  }, [workflowItems, runnableItems]);

  React.useEffect(() => {
    setRowData((data) => ({ ...data, showValidation }));
  }, [showValidation]);

  React.useEffect(() => {
    setNodes((nodes) => {
      return nodes.map((node: Node) => {
        const isActive = node.data.uniqueId === activeNode?.data.uniqueId;
        const nodeData = isActive ? activeNode?.data : node.data;
        return {
          ...node,
          data: {
            ...nodeData,
            isActive,
            isError:
              !loading &&
              ((showValidation && !nodeData.workflow && !nodeData.runnable) ||
                ((nodeData.workflow || nodeData.runnable) &&
                  !searchItemsNameMappings?.[nodeData.runnable || nodeData.workflow])),
            label: getLabel({
              uniqueId: nodeData.uniqueId,
              displayName: nodeData.displayName || '',
              runnable: nodeData.runnable,
              workflow: nodeData.workflow,
              altName: searchItemsNameMappings?.[nodeData.runnable || nodeData.workflow],
            }),
          },
        };
      });
    });
  }, [searchItemsNameMappings, activeNode, showValidation, loading]);

  return (
    <div className={classNames.rowContainer}>
      <WorkflowCanvasWidget {...widgetProps} />
      <WorkflowCanvasPanel {...panelProps} />
      <ReactFlow
        id="react-flow"
        ref={reactFlowRef}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodesDelete={onNodesDelete}
        onEdgesDelete={onEdgesDelete}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onNodeClick={onNodeSelect}
        onEdgeClick={onEdgeSelect}
        onPaneClick={onCanvasClick}
        onPaneContextMenu={onPaneContextMenu}
        onNodeContextMenu={onNodeContextMenu}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <Controls />
        <Background />
        {menu && <ContextMenu {...menu} />}
        <DragAndDropItem />
      </ReactFlow>
    </div>
  );
};
