import { fetchWrapRPC } from '../services/api';
import {
  CreateWorkspaceRequest,
  ListWorkspacesRequest,
  WorkspaceService_CreateWorkspace,
  WorkspaceService_ListWorkspaces,
} from './gen/ai/h2o/workspace/v1/workspace_api_pb';

export class AuthzServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async getWorkspaces(req: ListWorkspacesRequest) {
    return await fetchWrapRPC(WorkspaceService_ListWorkspaces, { basePath: this.basePath })({ ...req });
  }

  public async createWorkspace(req: CreateWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_CreateWorkspace, { basePath: this.basePath })({ ...req });
  }
}
