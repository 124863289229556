import { IContextualMenuItem, MessageBarType } from '@fluentui/react';
import { Button, ConfirmDialog, buttonStylesIcon, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ExecuteWorkflowResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ENDPOINTS } from './apiEndpoints';
import { FailedToLoadView } from './FailedToLoadView';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { NoItemView } from './NoItemView';
import WidgetList from './WidgetList';
import { ListWorkflowsResponseFixed, WorkflowFixed } from './WorkflowTabCanvas';
import { RowHeaderTitle } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

type WorkflowItem = WorkflowFixed & {
  stepCount: number;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickRun: () => void;
};

export const ContextMenuIconButton = ({ items }: { items: IContextualMenuItem[] }) => (
  <Button
    title="More"
    styles={[buttonStylesIcon, { menuIcon: { display: 'none' } }]}
    iconProps={{ style: { margin: 0 }, iconName: 'More' }}
    menuProps={{
      items,
      directionalHintFixed: true,
      calloutProps: { styles: { root: { padding: '6px 0px' } } },
    }}
  />
);

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 800,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName, onClickEdit }: WorkflowItem) =>
          RowHeaderTitle({ title: displayName, onClick: onClickEdit }),
        iconProps: {
          iconName: 'ProjectCollection',
        },
      },
    },
  },
  {
    key: 'steps',
    name: 'Steps',
    fieldName: 'stepCount',
    minWidth: 80,
    maxWidth: 100,
  },
  {
    key: 'createdAt',
    name: 'Create Time',
    fieldName: 'createTime',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    key: 'executedBy',
    name: 'Created By',
    fieldName: 'creatorDisplayName',
    minWidth: 100,
    maxWidth: 200,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ onClickDelete, onClickEdit, onClickRun }: WorkflowItem) => (
          // TODO: Show View/Edit button on permissions base.
          // TODO: Use theme prop for colors.
          <ContextMenuIconButton
            items={[
              {
                key: 'edit',
                text: 'Edit',
                onClick: onClickEdit,
                iconProps: { iconName: 'Edit', style: { color: 'var(--h2o-gray900)' } },
              },
              {
                key: 'run',
                text: 'Run workflow',
                onClick: onClickRun,
                iconProps: { iconName: 'Play', style: { color: 'var(--h2o-blue500)' } },
                style: { color: 'var(--h2o-blue500)' },
              },
              {
                key: 'delete',
                text: 'Delete',
                onClick: onClickDelete,
                style: { color: 'var(--h2o-red400)' },
                iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const Workflows = () => {
  const history = useHistory(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    onAction = () => history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}${ENDPOINTS.WORKFLOWS}/create-new`),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    [workflowItems, setWorkflowItems] = React.useState<WorkflowFixed[]>(),
    // TODO: Handle loading state.
    [loading, setLoading] = React.useState(true),
    [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false),
    [dialogAction, setDialogAction] = React.useState<() => unknown>(),
    closeDeleteDialog = () => setIsDeleteDialogOpen(false),
    onEditWorkflow = (workflow: WorkflowFixed) => history.push(`/orchestrator/${workflow.name}`, { state: workflow }),
    onDeleteWorkflow = (name: string) => {
      setDialogAction(() => () => {
        void deleteWorkflow(name);
        closeDeleteDialog();
      });
      setIsDeleteDialogOpen(true);
    },
    runWorkflow = async (name: string) => {
      await orchestratorService
        .executeWorkflow({ name })
        .then((_data: ExecuteWorkflowResponse) => {
          addToast({
            messageBarType: MessageBarType.success,
            message: `Workflow ${name} is running.`,
          });
          history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}/activeExecutions`);
        })
        .catch((err) => {
          const message = `Failed to run workflow: ${err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        });
    },
    fetchWorkflows = React.useCallback(async () => {
      setLoading(true);
      try {
        const data: ListWorkflowsResponseFixed = await orchestratorService.getWorkflows({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        const workflowItems: WorkflowItem[] | undefined = data?.workflows
          ? data.workflows.map((workflow) => ({
              ...workflow,
              stepCount: workflow.steps?.length || 0,
              onClickEdit: () => onEditWorkflow(workflow),
              // TODO: Handle if name is missing.
              onClickDelete: () => onDeleteWorkflow(workflow.name || ''),
              onClickRun: () => runWorkflow(workflow.name || ''),
            }))
          : undefined;
        if (data && !workflowItems) console.error('No workflows found in the response.');
        setWorkflowItems(workflowItems);
      } catch (err) {
        const message = `Failed to fetch workflows: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoading(false);
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService]),
    deleteWorkflow = React.useCallback(
      async (name: string) => {
        setLoading(true);
        try {
          await orchestratorService.deleteWorkflow({ name });
          void fetchWorkflows();
        } catch (err) {
          const message = `Failed to delete workflow: ${err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setLoading(false);
        }
      },
      [orchestratorService, fetchWorkflows]
    );

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) void fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  return (
    <NavigationWrapper>
      {workflowItems?.length && !loading ? <Header action="Create workflow" onActionClick={onAction} /> : null}
      <ConfirmDialog
        title="Delete Workflow"
        hidden={!isDeleteDialogOpen}
        onConfirm={dialogAction!}
        onDismiss={closeDeleteDialog}
        msg="Are you sure you want to delete this workflow? Once it is deleted it cannot be restored."
        confirmationButtonText="Delete"
        dismissalButtonText="Cancel"
        // TODO: Add danger button style.
      />
      <WidgetList
        columns={columns}
        items={workflowItems}
        loading={loading}
        NoItemsContent={NoItemView({
          title: 'Workflows',
          description: 'There are no workflows created in this workspace. Create the first one.',
          actionTitle: 'Create workflow',
          onActionClick: onAction,
          actionIcon: 'Add',
          backgroundImage: `url(${require('./assets/no-workflows-background.gif')})`,
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load workflows',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchWorkflows,
          actionIcon: 'Refresh',
        })}
      />
    </NavigationWrapper>
  );
};

export default Workflows;
