import { MessageBarType } from '@fluentui/react';
import { ConfirmDialog, IconButton, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ListExecutorPoolsResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_service_pb';
import { Runnable } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_pb';
import { ListRunnablesResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ENDPOINTS } from './apiEndpoints';
import { FailedToLoadView } from './FailedToLoadView';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { NoItemView } from './NoItemView';
import { CODE_TYPE_DISPLAY_NAMES, LANGUAGE_DISPLAY_NAMES } from './RunnableDetail';
import WidgetList from './WidgetList';
import { ContextMenuIconButton } from './Workflows';
import { RowHeaderTitle } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

type RunnableItem = Runnable & {
  executorPoolDisplayName: string;
  programmingLanguage: string;
  codeType: string;
  onClickDelete: () => void;
  onClickEdit: () => void;
};

type KeyNameMappings = { [key: string]: string };

type WidgetIconButtonProps = {
  iconName: string;
  onClick: () => void;
  iconColor?: string;
  backgroundColor?: string;
  title?: string;
  hasBorder?: boolean;
};

export const WidgetIconButton = ({
  iconName,
  onClick,
  iconColor,
  backgroundColor,
  title,
  hasBorder = true,
}: WidgetIconButtonProps) => (
  <IconButton
    title={title || iconName}
    onClick={onClick}
    styles={{
      root: {
        width: 30,
        height: 30,
        marginLeft: 3,
        marginRight: 3,
        backgroundColor: backgroundColor,
        border: hasBorder ? undefined : 'none',
      },
      rootHovered: {
        backgroundColor: backgroundColor,
      },
    }}
    iconProps={{
      iconName: iconName,
      styles: {
        root: {
          fontSize: 18,
          color: iconColor,
        },
      },
    }}
  />
);

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 360,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName, onClickEdit }: RunnableItem) =>
          RowHeaderTitle({ title: displayName, onClick: onClickEdit }),
        emptyMessage: 'No Description',
        iconProps: {
          iconName: 'ProjectCollection',
        },
      },
    },
  },
  {
    key: 'executorPool',
    name: 'Executor Pool',
    fieldName: 'executorPoolDisplayName',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'creator',
    name: 'Creator',
    fieldName: 'creatorDisplayName',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'createTime',
    name: 'Created At',
    fieldName: 'createTime',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'programmingLanguage',
    name: 'Language',
    fieldName: 'programmingLanguage',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'codeType',
    name: 'Code Type',
    fieldName: 'codeType',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 140,
    maxWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ onClickDelete, onClickEdit }: RunnableItem) => (
          // TODO: Show View/Edit button on permissions base.
          <ContextMenuIconButton
            items={[
              {
                key: 'edit',
                text: 'Edit',
                onClick: onClickEdit,
                iconProps: { iconName: 'Edit', style: { color: 'var(--h2o-gray900)' } },
              },
              {
                key: 'delete',
                text: 'Delete',
                onClick: onClickDelete,
                style: { color: 'var(--h2o-red400)' },
                iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const Runnables = () => {
  const history = useHistory(),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false),
    [dialogAction, setDialogAction] = React.useState<() => unknown>(),
    [loading, setLoading] = React.useState(true),
    [runnableItems, setRunnableItems] = React.useState<RunnableItem[]>(),
    [poolKeysToNames, setPoolKeysToNames] = React.useState<KeyNameMappings>({}),
    closeDeleteDialog = () => setIsDeleteDialogOpen(false),
    onEditRunnable = (r: Runnable) => history.push(`/orchestrator/${r.name}`, { state: r }),
    onDeleteRunnable = (id: string) => {
      setDialogAction(() => () => {
        void deleteRunnable(id);
        closeDeleteDialog();
      });
      setIsDeleteDialogOpen(true);
    },
    onCreateButtonClick = () => history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}${ENDPOINTS.RUNNABLES}/create-new`),
    fetchExecutionPools = React.useCallback(async () => {
      setLoading(true);
      try {
        const data: ListExecutorPoolsResponse = await orchestratorService.getExecutorPools({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setPoolKeysToNames(
          (data.executorPools || []).reduce((acc, pool) => {
            if (pool.name) acc[pool.name] = pool.displayName || pool.name;
            return acc;
          }, {} as KeyNameMappings)
        );
      } catch (err) {
        const message = `Failed to fetch execution pools: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoading(false);
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService]),
    fetchRunnables = React.useCallback(async () => {
      setLoading(true);
      try {
        const data: ListRunnablesResponse = await orchestratorService.getRunnables({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        const items: RunnableItem[] | undefined = data?.runnables
          ? data.runnables.map((r) => ({
              ...r,
              // TODO: Handle missing fields ('').
              programmingLanguage: r.code?.programmingLanguage
                ? LANGUAGE_DISPLAY_NAMES[r.code.programmingLanguage]
                : '',
              codeType: r.code?.type ? CODE_TYPE_DISPLAY_NAMES[r.code.type] : '',
              // TODO: Update everytime after executor pools are fetched.
              executorPoolDisplayName: r.executorPool ? poolKeysToNames[r.executorPool] || r.executorPool : '',
              // TODO: Handle if name is missing.
              onClickDelete: () => onDeleteRunnable(r.name || ''),
              onClickEdit: () => onEditRunnable(r),
            }))
          : undefined;
        if (data && !items) console.error('No runnables found in the response.');
        setRunnableItems(items);
      } catch (err) {
        const message = `Failed to fetch runnables: ${err}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoading(false);
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService, poolKeysToNames]),
    deleteRunnable = React.useCallback(
      async (id: string) => {
        setLoading(true);
        try {
          await orchestratorService.deleteRunnable({ name: id });
          void fetchRunnables();
        } catch (err) {
          const message = `Failed to delete runnable: ${err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setLoading(false);
        }
      },
      [orchestratorService, fetchRunnables]
    );

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchExecutionPools();
      void fetchRunnables();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  return (
    <NavigationWrapper>
      {runnableItems?.length && !loading ? (
        <Header action="Create runnable" onActionClick={onCreateButtonClick} actionIcon="Add" />
      ) : null}
      <ConfirmDialog
        title="Delete Runnable"
        hidden={!isDeleteDialogOpen}
        onConfirm={dialogAction!}
        onDismiss={closeDeleteDialog}
        msg="Are you sure you want to delete this runnable? Once it is deleted it cannot be restored."
        confirmationButtonText="Delete"
        dismissalButtonText="Cancel"
        // TODO: Add danger button style.
      />
      <WidgetList
        columns={columns}
        items={runnableItems}
        loading={loading}
        NoItemsContent={NoItemView({
          title: 'Runnables',
          description: 'There are no runnables created in this workspace. Create the first one.',
          actionTitle: 'Create runnable',
          onActionClick: onCreateButtonClick,
          actionIcon: 'Add',
          // TODO: Add image.
          // backgroundImage: `url(${require('./assets/no-runnables-background.gif')})`,
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load runnables',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchRunnables,
          actionIcon: 'Refresh',
        })}
      />
    </NavigationWrapper>
  );
};

export default Runnables;
